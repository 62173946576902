<template>
  <RevTooltip v-if="hasErrors" content-class-names="flex flex-col gap-12">
    <template #trigger="{ show, hide }">
      <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
      <div
        :class="innerClass"
        :tabindex="tabIndex"
        @focusin="show"
        @focusout="hide"
        @mouseleave="hide"
        @mouseover="show"
      >
        <slot />
      </div>
    </template>
    <div v-for="message in errorMessages" :key="message">
      {{ message }}
    </div>
  </RevTooltip>

  <div v-else :class="innerClass" :tabindex="tabIndex">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { paymentAPI } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevTooltip } from '@ds/components/Tooltip'

import { paymentGroupConfig } from '../../helpers/methods'

const props = withDefaults(
  defineProps<{
    paymentMethods: paymentAPI.PaymentMethod[]
    innerClass?: string
  }>(),
  {
    innerClass: '',
  },
)

const i18n = useI18n()

type ErrorRecord = Partial<
  Record<paymentAPI.PaymentError, paymentAPI.PaymentGroup>
>

function getPaymentGroupIdByErrorCode(paymentMethod: paymentAPI.PaymentMethod) {
  return paymentMethod.errors.reduce<ErrorRecord>(
    (acc2, errorCode) => ({ ...acc2, [errorCode]: paymentMethod.group }),
    {},
  )
}

// Create a dictionary indexed by error code, to avoid repeating twice the same error.
const paymentGroupIdsByErrorCode = computed(() =>
  props.paymentMethods.reduce<ErrorRecord>(
    (acc1, paymentMethod) => ({
      ...getPaymentGroupIdByErrorCode(paymentMethod),
      ...acc1,
    }),
    {},
  ),
)

const errorMessages = computed(() =>
  Object.entries(paymentGroupIdsByErrorCode.value).map((entries) => {
    const errorCode = entries[0] as paymentAPI.PaymentError
    const paymentGroupId = entries[1]

    const methods = props.paymentMethods
    const groupConfig = paymentGroupConfig(paymentGroupId, { methods })
    const translation = groupConfig?.errorMessages?.[errorCode]

    return translation ? i18n(translation) : errorCode
  }),
)

const hasErrors = computed(() => !isEmpty(errorMessages.value))

const tabIndex = computed(() => (hasErrors.value ? '0' : '-1'))
</script>
